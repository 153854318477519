import { v4 as uuidv4 } from 'uuid';
var SessionConfig = /** @class */ (function () {
    function SessionConfig(roomId) {
        this.sessionId = uuidv4();
        this.attendeeId = uuidv4();
        this.roomId = uuidv4();
        this.roomId = roomId.length == 0 ? uuidv4() : roomId;
    }
    Object.defineProperty(SessionConfig.prototype, "SessionId", {
        get: function () {
            return this.sessionId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SessionConfig.prototype, "AttendeeId", {
        get: function () {
            return this.attendeeId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SessionConfig.prototype, "RoomId", {
        get: function () {
            return this.roomId;
        },
        enumerable: false,
        configurable: true
    });
    return SessionConfig;
}());
export { SessionConfig };
