import * as SimplePeer from "simple-peer";
var PeerConnector = /** @class */ (function () {
    function PeerConnector(shouldOffer) {
        var _this = this;
        this.localStream = null;
        this.localTracks = [];
        var options = {
            initiator: shouldOffer
        };
        this.connector = new SimplePeer(options);
        this.connector.on('signal', function (data) {
            _this.OnSendMessage(data, data.type);
        });
        this.connector.on('stream', function (stream) {
            _this.OnHasStream(stream);
            _this.OnConnectionChanged('got stream ' + stream.id);
        });
        this.connector.on('error', function (error) {
            _this.OnConnectionChanged('error: ' + error.message);
            _this.Shutdown();
        });
        this.connector.on('close', function () {
            _this.OnConnectionChanged('lost');
            _this.Shutdown();
        });
    }
    PeerConnector.prototype.Signal = function (signalData) {
        if (this.isClosed) {
            return;
        }
        this.connector.signal(signalData);
    };
    PeerConnector.prototype.Shutdown = function () {
        if (this.isClosed) {
            return;
        }
        this.isClosed = true;
        this.connector.removeAllListeners();
        this.connector.destroy();
        this.OnClose();
    };
    PeerConnector.prototype.SendStream = function (stream) {
        var _this = this;
        if (this.isClosed) {
            return;
        }
        this.StopStream();
        this.localStream = stream;
        this.localTracks = stream.getTracks();
        this.localTracks.forEach(function (track) { return _this.connector.addTrack(track, _this.localStream); });
    };
    PeerConnector.prototype.StopStream = function () {
        var _this = this;
        this.localTracks.forEach(function (track) { return _this.connector.removeTrack(track, _this.localStream); });
        this.localStream = null;
        this.localTracks = [];
    };
    return PeerConnector;
}());
export { PeerConnector };
