var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var UserMediaSettingType;
(function (UserMediaSettingType) {
    UserMediaSettingType[UserMediaSettingType["Generic"] = 0] = "Generic";
    UserMediaSettingType[UserMediaSettingType["Range"] = 1] = "Range";
    UserMediaSettingType[UserMediaSettingType["Select"] = 2] = "Select";
})(UserMediaSettingType || (UserMediaSettingType = {}));
var UserMediaSetting = /** @class */ (function () {
    function UserMediaSetting(value, name, description, category, hidden) {
        this.Type = UserMediaSettingType.Generic;
        this.Name = name;
        this.Description = description;
        this.Category = category;
        this.Hidden = hidden;
        this.Value = value;
    }
    return UserMediaSetting;
}());
export { UserMediaSetting };
var UserMediaSettingsRange = /** @class */ (function (_super) {
    __extends(UserMediaSettingsRange, _super);
    function UserMediaSettingsRange(min, max, step, value, name, description, category, hidden) {
        var _this = _super.call(this, value, name, description, category, hidden) || this;
        _this.Min = min;
        _this.Max = max;
        _this.Step = step;
        _this.Type = UserMediaSettingType.Range;
        return _this;
    }
    return UserMediaSettingsRange;
}(UserMediaSetting));
export { UserMediaSettingsRange };
var UserSettingsSelection = /** @class */ (function (_super) {
    __extends(UserSettingsSelection, _super);
    function UserSettingsSelection(value, options, name, description, category, hidden) {
        var _this = _super.call(this, value, name, description, category, hidden) || this;
        _this.Options = [];
        _this.Options = options;
        _this.Type = UserMediaSettingType.Select;
        return _this;
    }
    return UserSettingsSelection;
}(UserMediaSetting));
export { UserSettingsSelection };
var UserMediaSettings = /** @class */ (function () {
    function UserMediaSettings() {
        this.ScreenEnabled = new UserMediaSetting(false, "Enable Screen", "Start sharing your screen", "Basic Screen", false);
        this.VideoEnabled = new UserMediaSetting(false, "Enable Video", "Start sending your camera", "Basic Video", false);
        this.VideoResolution = new UserSettingsSelection("720p", ["480p", "720p", "1080p"], "Resolution", "Sets the ideal resolution for your camera. Your web browser might choose to ignore this.", "Advanced Video", false);
        this.VideoFrameRate = new UserMediaSettingsRange(15, 60, 5, 20, "Frame Rate", "Sets the ideal frame rate for your camera. Your web browser might choose to ignore this.", "Advanced Video", false);
        this.AudioEnabled = new UserMediaSetting(true, "Enable Audio", null, "Basic Audio", false);
        this.AudioLocalMeter = new UserMediaSetting(true, "Enable Audio Meter", null, "Basic Audio", false);
        this.AudioGain = new UserMediaSettingsRange(0.5, 5, 0.5, 1, "Input Gain", "The amount of amplification to add to your microphone", "Basic Audio", false);
        this.AudioLocalListen = new UserMediaSettingsRange(0, 1, 0.05, 0, "Self Listen Volume", "Allow you to hear your own microphone, as the other attendees will hear it", "Advanced Audio", false);
        this.AudioEchoCancellation = new UserMediaSetting(false, "Enable Echo Cancellation", "If you're using speakers, this will stop the other attendees from hearing themselves", "Advanced Audio", false);
        this.AudioAutoGainControl = new UserMediaSetting(false, "Enable Auto Gain", "Enable automatic volume control", "Advanced Audio", false);
        this.AudioNoiseSuppression = new UserMediaSetting(false, "Enable Noise Suppression", "Try to filter out background sounds", "Advanced Audio", false);
        this.AudioStereo = new UserMediaSetting(false, "Enable Stereo (Firefox attendees only)", null, "Advanced Audio", false);
        this.AudioCompressor = new UserMediaSetting(false, "Enable Dynamics Compressor", "Lowers the volume of the loudest parts of the signal in order to help prevent clipping and distortion", "Advanced Audio", false);
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode/threshold
        this.AudioCompressorThreshold = new UserMediaSettingsRange(-100, 0, 1, -24, "Compressor Threshold", "The decibel value above which the compression will start taking effect", "Advanced Audio", true);
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode/knee
        this.AudioCompressorKnee = new UserMediaSettingsRange(0, 40, 1, 30, "Compressor Knee", "The decibel value representing the range above the threshold where the curve smoothly transitions to the compressed portion", "Advanced Audio", true);
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode/ratio
        this.AudioCompressorRatio = new UserMediaSettingsRange(1, 20, 1, 12, "Compressor Ratio", "The amount of change, in dB, needed in the input for a 1 dB change in the output", "Advanced Audio", true);
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode/attack
        this.AudioCompressorAttack = new UserMediaSettingsRange(0, 1, 0.001, 0.003, "Compressor Attack", "The amount of time, in seconds, required to reduce the gain by 10 dB", "Advanced Audio", true);
        // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode/release
        this.AudioCompressorRelease = new UserMediaSettingsRange(0, 1, 0.001, 0.25, "Compressor Release", "The amount of time, in seconds, required to increase the gain by 10 dB", "Advanced Audio", true);
    }
    return UserMediaSettings;
}());
var UserMedia = /** @class */ (function () {
    function UserMedia() {
        this.remoteStreams = {};
        this.currentSettings = new UserMediaSettings();
    }
    UserMedia.prototype.GetSettings = function () {
        return JSON.parse(JSON.stringify(this.currentSettings));
    };
    UserMedia.prototype.SetSettings = function (newSettings) {
        return __awaiter(this, void 0, void 0, function () {
            var shouldRefreshMediaAccess;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.currentSettings.ScreenEnabled.Value !== newSettings.ScreenEnabled.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioAutoGainControl.Value !== newSettings.AudioAutoGainControl.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioEchoCancellation.Value !== newSettings.AudioEchoCancellation.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioNoiseSuppression.Value !== newSettings.AudioNoiseSuppression.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioLocalMeter.Value !== newSettings.AudioLocalMeter.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.VideoEnabled.Value !== newSettings.VideoEnabled.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.VideoResolution.Value !== newSettings.VideoResolution.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.VideoFrameRate.Value !== newSettings.VideoFrameRate.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioStereo.Value !== newSettings.AudioStereo.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        if (this.currentSettings.AudioCompressor.Value !== newSettings.AudioCompressor.Value) {
                            shouldRefreshMediaAccess = true;
                        }
                        // These are cheap so don't need to be switched on/off
                        this.SetCompressionParameters(newSettings);
                        this.SetGainParameters(newSettings);
                        this.currentSettings = newSettings;
                        if (!shouldRefreshMediaAccess) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.GetMediaStream()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    UserMedia.prototype.GetAudioContext = function () {
        var windowDictionary = window;
        // Fall back to webkit audio context
        var audioContext = windowDictionary['AudioContext'] || windowDictionary['webkitAudioContext'];
        // Lazy initialise the audio context
        if (this.audioContext == null) {
            this.audioContext = new audioContext();
        }
        return this.audioContext;
    };
    UserMedia.prototype.AddRemoteStream = function (tag, mediaStream) {
        if (this.outputAnalyserNode == null) {
            this.outputAnalyserNode = this.GetAudioContext().createAnalyser();
            this.outputAnalyserNode.connect(this.GetAudioContext().destination);
        }
        if (mediaStream.getAudioTracks().length === 0) {
            console.error("Ignoring remote stream with zero audio tracks");
            return;
        }
        this.RemoveRemoteStream(tag);
        console.log("Got media stream", mediaStream);
        this.remoteStreams[tag] = this.GetAudioContext().createMediaStreamSource(mediaStream);
        this.remoteStreams[tag].connect(this.outputAnalyserNode);
    };
    UserMedia.prototype.RemoveRemoteStream = function (tag) {
        if (this.remoteStreams.hasOwnProperty(tag)) {
            this.remoteStreams[tag].disconnect(this.outputAnalyserNode);
            delete this.remoteStreams[tag];
        }
    };
    UserMedia.prototype.GetMediaStream = function () {
        return __awaiter(this, void 0, void 0, function () {
            var audioConstraints, videoResolutions, videoWidthRange, videoHeightRange, videoFrameRate, videoConstraints, constraints, stream, audioTracks, videoTracks, screenStream, inputStreamNode, inputStream;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        audioConstraints = {};
                        audioConstraints.noiseSuppression = this.currentSettings.AudioNoiseSuppression.Value;
                        audioConstraints.echoCancellation = this.currentSettings.AudioEchoCancellation.Value;
                        audioConstraints.autoGainControl = this.currentSettings.AudioAutoGainControl.Value;
                        videoResolutions = {
                            '480p': [854, 480],
                            '720p': [1280, 720],
                            '1080p': [1920, 1080]
                        };
                        videoWidthRange = {};
                        videoWidthRange.ideal = videoResolutions[this.currentSettings.VideoResolution.Value][0];
                        videoHeightRange = {};
                        videoHeightRange.ideal = videoResolutions[this.currentSettings.VideoResolution.Value][1];
                        videoFrameRate = {};
                        videoFrameRate.ideal = this.currentSettings.VideoFrameRate.Value;
                        videoConstraints = {};
                        videoConstraints.width = videoWidthRange;
                        videoConstraints.height = videoHeightRange;
                        videoConstraints.frameRate = videoFrameRate;
                        constraints = {};
                        constraints.audio = audioConstraints;
                        if (this.currentSettings.VideoEnabled.Value) {
                            constraints.video = videoConstraints;
                        }
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia(constraints)];
                    case 1:
                        stream = _a.sent();
                        audioTracks = stream.getAudioTracks();
                        console.assert(audioTracks.length == 1, "Expected 1 audio track, there are " + audioTracks.length);
                        if (!this.currentSettings.ScreenEnabled.Value) return [3 /*break*/, 3];
                        return [4 /*yield*/, navigator.mediaDevices.getDisplayMedia()];
                    case 2:
                        screenStream = _a.sent();
                        videoTracks = screenStream.getVideoTracks();
                        return [3 /*break*/, 4];
                    case 3:
                        videoTracks = stream.getVideoTracks();
                        _a.label = 4;
                    case 4:
                        console.assert(videoTracks.length <= 1, "Expected 1 or 0 video tracks, there are " + videoTracks.length);
                        this.inputStreamAudioNode = this.ProcessAudioTrackToMono(stream);
                        if (this.inputStreamMonitorAudioNode != null) {
                            this.inputStreamMonitorAudioNode.disconnect();
                        }
                        this.inputStreamMonitorAudioNode = this.GetAudioContext().createGain();
                        this.inputStreamMonitorAudioNode.gain.value = this.currentSettings.AudioLocalListen.Value;
                        this.inputStreamMonitorAudioNode.connect(this.GetAudioContext().destination);
                        this.inputStreamAudioNode.connect(this.inputStreamMonitorAudioNode);
                        inputStreamNode = this.GetAudioContext().createMediaStreamDestination();
                        this.inputStreamAudioNode.connect(inputStreamNode);
                        inputStream = inputStreamNode.stream;
                        if (videoTracks.length > 0) {
                            inputStream.addTrack(videoTracks[0]);
                        }
                        if (this.OnMediaStreamAvailable != null) {
                            this.OnMediaStreamAvailable(inputStream);
                        }
                        return [2 /*return*/, inputStream];
                }
            });
        });
    };
    UserMedia.GetTimeDomainDataFromAnalyser = function (analyserNode) {
        if (analyserNode == null) {
            return new Float32Array(0);
        }
        var sampleBuffer = new Float32Array(analyserNode.fftSize);
        analyserNode.getFloatTimeDomainData(sampleBuffer);
        return sampleBuffer;
    };
    UserMedia.GetFrequencyDataFromAnalyser = function (analyserNode) {
        if (analyserNode == null) {
            return new Uint8Array(0);
        }
        var sampleBuffer = new Uint8Array(analyserNode.frequencyBinCount);
        analyserNode.getByteFrequencyData(sampleBuffer);
        return sampleBuffer;
    };
    UserMedia.prototype.SampleInputTimeDomain = function () {
        return UserMedia.GetTimeDomainDataFromAnalyser(this.inputAnalyserNode);
    };
    UserMedia.prototype.SampleOutputTimeDomain = function () {
        return UserMedia.GetTimeDomainDataFromAnalyser(this.outputAnalyserNode);
    };
    UserMedia.prototype.SampleInputFrequency = function () {
        return UserMedia.GetFrequencyDataFromAnalyser(this.inputAnalyserNode);
    };
    UserMedia.prototype.SampleOutputFrequency = function () {
        return UserMedia.GetFrequencyDataFromAnalyser(this.outputAnalyserNode);
    };
    UserMedia.prototype.SetGainParameters = function (newSettings) {
        if (this.inputGainNode == null) {
            return;
        }
        if (!newSettings.AudioEnabled.Value) {
            this.inputGainNode.gain.value = 0;
            return;
        }
        // In Chrome and Firefox, if a user has multiple channels
        // the gain needs to be multiplied by each. For example,
        // with 2 channels, the overall volume maxes out at 50%.
        // I'm not sure whether this is a browser bug or expected.
        this.inputGainNode.gain.value = this.inputAudioChannels * newSettings.AudioGain.Value;
        if (this.inputStreamMonitorAudioNode != null) {
            this.inputStreamMonitorAudioNode.gain.value = newSettings.AudioLocalListen.Value;
        }
    };
    UserMedia.prototype.SetCompressionParameters = function (newSettings) {
        if (this.inputCompressorNode == null) {
            return;
        }
        this.inputCompressorNode.threshold.value = newSettings.AudioCompressorThreshold.Value;
        this.inputCompressorNode.knee.value = newSettings.AudioCompressorKnee.Value;
        this.inputCompressorNode.ratio.value = newSettings.AudioCompressorRatio.Value;
        this.inputCompressorNode.attack.value = newSettings.AudioCompressorAttack.Value;
        this.inputCompressorNode.release.value = newSettings.AudioCompressorRelease.Value;
    };
    UserMedia.prototype.ProcessAudioTrackToMono = function (stream) {
        var source = this.GetAudioContext().createMediaStreamSource(stream);
        this.inputAudioChannels = source.channelCount;
        this.inputGainNode = this.GetAudioContext().createGain();
        this.inputGainNode.channelCount = this.currentSettings.AudioStereo.Value ? 2 : 1;
        this.inputGainNode.channelCountMode = "explicit";
        this.SetGainParameters(this.currentSettings);
        source.connect(this.inputGainNode);
        var lastNode = this.inputGainNode;
        if (this.currentSettings.AudioCompressor.Value) {
            this.inputCompressorNode = this.GetAudioContext().createDynamicsCompressor();
            this.SetCompressionParameters(this.currentSettings);
            lastNode.connect(this.inputCompressorNode);
            lastNode = this.inputCompressorNode;
        }
        else {
            this.inputCompressorNode = null;
        }
        this.inputAnalyserNode = this.GetAudioContext().createAnalyser();
        lastNode.connect(this.inputAnalyserNode);
        return this.inputAnalyserNode;
    };
    return UserMedia;
}());
export { UserMedia };
