var ClientLocation = /** @class */ (function () {
    function ClientLocation() {
    }
    return ClientLocation;
}());
export { ClientLocation };
var ConnectionManager = /** @class */ (function () {
    function ConnectionManager(broker, sessionConfig, peerConnectorFactory) {
        var _this = this;
        this.connectors = {};
        this.broker = broker;
        this.sessionConfig = sessionConfig;
        this.peerConnectorFactory = peerConnectorFactory;
        this.broker.OnMessage = function (message) { return _this.OnMessage(message); };
    }
    ConnectionManager.prototype.RefreshLocalStream = function () {
        var localStream = this.OnNeedLocalStream();
        for (var clientId in this.connectors) {
            if (this.connectors.hasOwnProperty(clientId)) {
                this.connectors[clientId].SendStream(localStream);
            }
        }
    };
    ConnectionManager.prototype.CreateConnector = function (fromId) {
        var _this = this;
        if (this.connectors.hasOwnProperty(fromId)) {
            return;
        }
        var shouldOffer = fromId < this.sessionConfig.AttendeeId;
        var peerConnector = this.peerConnectorFactory.CreatePeerConnector(shouldOffer);
        peerConnector.OnConnectionChanged = function (change) { return _this.OnConnectionChanged(fromId, change); };
        peerConnector.OnClose = function () { return _this.ProcessClose(fromId); };
        peerConnector.OnHasStream = function (stream) { return _this.OnHasStream(fromId, stream); };
        peerConnector.OnSendMessage = function (payload, type) { return _this.broker.Send(payload, type, fromId); };
        peerConnector.SendStream(this.OnNeedLocalStream());
        this.connectors[fromId] = peerConnector;
    };
    ConnectionManager.prototype.ProcessClose = function (fromId) {
        delete this.connectors[fromId];
        this.OnClose(fromId);
    };
    ;
    ConnectionManager.prototype.OnMessage = function (message) {
        if (message.FromId == this.sessionConfig.AttendeeId) {
            // Ignore self messages
            return;
        }
        this.CreateConnector(message.FromId);
        switch (message.Type) {
            case 'location':
                this.ProcessLocation(message);
                break;
            case 'discover':
                this.broker.Send({}, 'acknowledge', message.FromId);
                break;
            case 'acknowledge':
                // no-op
                break;
            default:
                this.connectors[message.FromId].Signal(message.Data);
                break;
        }
    };
    ConnectionManager.prototype.ProcessLocation = function (message) {
        var location = new ClientLocation();
        location.CityName = message.Data["cityName"];
        location.CountryName = message.Data["countryName"];
        location.CountryCode = message.Data["countryCode"];
        location.ContinentName = message.Data["continentName"];
        location.SubdivisionName = message.Data["subdivisionName"];
        this.OnLocation(message.FromId, location);
    };
    return ConnectionManager;
}());
export { ConnectionManager };
