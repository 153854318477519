import { UserMediaSettingType } from "./UserMedia";
var RemoteMedia = /** @class */ (function () {
    function RemoteMedia() {
    }
    return RemoteMedia;
}());
var MainUI = /** @class */ (function () {
    function MainUI(chatApp, userMedia) {
        this.remoteVideo = {};
        this.inputVolumeHistogram = [];
        this.ouputVolumeHistogram = [];
        this.shouldDrawVolumeHistogram = true;
        this.chatApp = chatApp;
        this.userMedia = userMedia;
        this.joinSound = document.createElement("audio");
        this.joinSound.src = "https://s.alanedwardes.com/633bc8cc-fc86-4ad1-a1fe-46d815dc4e29.mp3";
        this.leaveSound = document.createElement("audio");
        this.leaveSound.src = "https://s.alanedwardes.com/59e427ea-fd86-4642-80a0-6fe6eba887d4.mp3";
    }
    MainUI.prototype.initialise = function () {
        var _this = this;
        this.drawAudioVisualisations();
        function hideControls() {
            document.querySelectorAll(".controls").forEach(function (node) { return node.classList.add('faded'); });
        }
        var timeout = setTimeout(hideControls, 10000);
        function ShowControls() {
            clearTimeout(timeout);
            timeout = setTimeout(hideControls, 10000);
            document.querySelectorAll(".controls").forEach(function (node) { return node.classList.remove('faded'); });
        }
        window.addEventListener('beforeunload', function (e) {
            if (document.querySelectorAll('.remoteVideo').length > 0) {
                e.preventDefault();
                e.returnValue = 'There are others in the chat session.';
            }
        });
        window.onmousemove = function () { return ShowControls(); };
        window.ontouchstart = function () { return ShowControls(); };
        this.chatApp.OnMessage = function (messageText, messageType) { return _this.logMessage(messageText, messageType); };
        if (window.location.search.startsWith('?')) {
            var settings_1 = this.userMedia.GetSettings();
            var search = window.location.search.substring(1).split('&');
            for (var i = 0; i < search.length; i++) {
                var parts = search[i].split('=').filter(decodeURIComponent);
                var settingName = parts[0];
                var settingValue = parts[1];
                if (!settings_1.hasOwnProperty(settingName)) {
                    continue;
                }
                var settingTypedValue = void 0;
                try {
                    settingTypedValue = this.parseStringToType(settingValue, typeof (settings_1[settingName].Value));
                }
                catch (err) {
                    this.logMessage("Unable to parse value for setting " + settingName + ". Please ensure it is of the right type and try again.", "fatal");
                    return;
                }
                settings_1[settingName].Value = settingTypedValue;
            }
            this.applyNewSettings(settings_1);
        }
        this.chatApp.OnRemoteStream = function (clientId, mediaStream) {
            _this.userMedia.AddRemoteStream(clientId, mediaStream);
            var remoteMedia;
            if (_this.remoteVideo.hasOwnProperty(clientId)) {
                remoteMedia = _this.remoteVideo[clientId];
            }
            else {
                var div = document.createElement("div");
                div.className = "remoteVideo";
                document.querySelector('#remoteVideo').appendChild(div);
                var video_1 = document.createElement("video");
                div.appendChild(video_1);
                remoteMedia = new RemoteMedia();
                remoteMedia.Element = div;
                remoteMedia.Stream = mediaStream;
                _this.remoteVideo[clientId] = remoteMedia;
            }
            var video = remoteMedia.Element.children[0];
            video.srcObject = mediaStream;
            video.muted = true;
            video.play();
            _this.flowRemoteVideo();
        };
        this.chatApp.OnLocalStream = function (mediaStream) {
            var video = document.querySelector('#localVideo');
            video.srcObject = mediaStream;
            video.play();
        };
        var selfNode = document.createElement("li");
        selfNode.innerHTML = 'You';
        document.querySelector("#attendeeList").appendChild(selfNode);
        this.chatApp.OnLocation = function (clientId, location) {
            var clientNode = _this.getClientNode(clientId);
            var labelNode = clientNode.querySelector('span.label');
            var locationNode = labelNode.querySelector('span.location');
            var shortLocation = location.CityName ? location.CityName + " " + location.CountryCode : location.CountryCode;
            if (locationNode === null) {
                locationNode = document.createElement("span");
                locationNode.title = location.SubdivisionName + ", " + location.CityName + ", " + location.CountryName + ", " + location.ContinentName;
                var flag = document.createElement("img");
                flag.src = "https://chat.alanedwardes.com/flags/" + location.CountryCode.toLowerCase() + ".png";
                flag.title = locationNode.title;
                flag.alt = flag.title;
                locationNode.appendChild(flag);
                locationNode.classList.add("location");
                labelNode.appendChild(locationNode);
            }
            var nameNode = labelNode.querySelector('span.name');
            nameNode.innerHTML = shortLocation;
        };
        this.chatApp.OnConnectionChanged = function (clientId, change) {
            var clientNode = _this.getClientNode(clientId);
            var statusNode = clientNode.querySelector('span.status');
            if (statusNode === null) {
                statusNode = document.createElement("span");
                statusNode.classList.add("status");
                clientNode.appendChild(statusNode);
            }
            statusNode.textContent = change;
        };
        this.chatApp.OnClose = function (clientId) {
            setTimeout(function () { return _this.clientDisconnected(clientId); }, 15000);
        };
        this.chatApp.Start();
        var lastCategory;
        var settings = this.userMedia.GetSettings();
        for (var key in settings) {
            if (settings.hasOwnProperty(key)) {
                if (settings[key].Hidden) {
                    continue;
                }
                var parentElement = void 0;
                if (key.startsWith("Audio")) {
                    parentElement = document.querySelector('#audioParameters');
                }
                if (key.startsWith("Video")) {
                    parentElement = document.querySelector('#videoParameters');
                }
                if (key.startsWith("Screen")) {
                    parentElement = document.querySelector('#screenParameters');
                }
                if (lastCategory != settings[key].Category) {
                    this.createCategoryTitle(settings[key].Category, parentElement);
                }
                lastCategory = settings[key].Category;
                this.createSetting(key, settings[key], parentElement);
            }
        }
        document.querySelector('#audioControlsButton').addEventListener('click', function () {
            document.querySelector('#audioControls').classList.remove("hidden");
        });
        document.querySelector('#videoControlsButton').addEventListener('click', function () {
            document.querySelector('#videoControls').classList.remove("hidden");
        });
        document.querySelector('#screenControlsButton').addEventListener('click', function () {
            document.querySelector('#screenControls').classList.remove("hidden");
        });
        document.querySelector('#attendeeWindowButton').addEventListener('click', function () {
            document.querySelector('#attendeeWindow').classList.remove("hidden");
        });
        document.querySelectorAll('.closeButton').forEach(function (element) {
            element.addEventListener('click', function (event) {
                var sourceElement = event.srcElement;
                sourceElement.parentElement.classList.add("hidden");
            });
        });
    };
    MainUI.prototype.countryCodeEmoji = function (country) {
        var offset = 127397;
        var f = country.codePointAt(0);
        var s = country.codePointAt(1);
        return String.fromCodePoint(f + offset) + String.fromCodePoint(s + offset);
    };
    MainUI.prototype.getClientNode = function (clientId) {
        var attendeeList = document.querySelector("#attendeeList");
        var clientNode = attendeeList.querySelector('li[data-connection-id="' + clientId + '"]');
        if (clientNode === null) {
            clientNode = document.createElement("li");
            clientNode.setAttribute("data-connection-id", clientId);
            var labelNode = document.createElement("span");
            labelNode.className = "label";
            clientNode.appendChild(labelNode);
            var nameNode = document.createElement("span");
            nameNode.innerHTML = clientId.substring(0, 6);
            nameNode.className = "name";
            labelNode.appendChild(nameNode);
            attendeeList.appendChild(clientNode);
            this.joinSound.play();
            this.logMessage("Someone connected!", "info");
        }
        return clientNode;
    };
    MainUI.prototype.clientDisconnected = function (clientId) {
        var clientNode = this.getClientNode(clientId);
        clientNode.parentElement.removeChild(clientNode);
        if (this.remoteVideo.hasOwnProperty(clientId)) {
            var remoteMedia = this.remoteVideo[clientId];
            remoteMedia.Element.parentElement.removeChild(remoteMedia.Element);
        }
        this.userMedia.RemoveRemoteStream(clientId);
        this.leaveSound.play();
    };
    MainUI.prototype.logMessage = function (messageText, messageType) {
        var timeoutHandle;
        if (messageType != "fatal") {
            timeoutHandle = setTimeout(function () {
                list.removeChild(container);
            }, 10000);
        }
        var list = document.querySelector(".messages");
        var container = document.createElement("div");
        container.className = messageType + "Message message";
        var closeButton = document.createElement("button");
        closeButton.className = "closeButton";
        closeButton.innerHTML = "✕";
        closeButton.onclick = function () {
            clearTimeout(timeoutHandle);
            list.removeChild(container);
        };
        container.appendChild(closeButton);
        var message = document.createElement("span");
        message.innerHTML = messageText;
        container.appendChild(message);
        list.appendChild(container);
    };
    MainUI.prototype.flowRemoteVideo = function () {
        var videos = Array.prototype.slice.call(document.querySelectorAll('.remoteVideo'));
        var videoCount = videos.length;
        //let rowCount = Math.ceil(videoCount / 2);
        var columnCount = Math.ceil(videoCount / 2);
        var currentColumn = 0;
        var currentRow = 0;
        while (videos.length > 0) {
            var video = videos.pop();
            video.style['grid-area'] = (currentRow + 1) + " / " + (currentColumn + 1) + " / span 1 / span 1";
            currentColumn++;
            if (currentColumn > columnCount - 1) {
                currentColumn = 0;
                currentRow++;
            }
        }
    };
    MainUI.prototype.createCategoryTitle = function (category, parent) {
        var title = document.createElement('h2');
        title.innerHTML = category;
        parent.appendChild(title);
    };
    MainUI.prototype.applyNewSettings = function (newSettings) {
        this.userMedia.SetSettings(newSettings);
        var oldShouldDrawVolumeHistogram = this.shouldDrawVolumeHistogram;
        this.shouldDrawVolumeHistogram = newSettings.AudioLocalMeter.Value;
        if (!oldShouldDrawVolumeHistogram && this.shouldDrawVolumeHistogram) {
            this.drawAudioVisualisations();
        }
    };
    MainUI.prototype.createSetting = function (settingKey, settingValue, parent) {
        var _this = this;
        var paragraph = document.createElement("p");
        parent.appendChild(paragraph);
        if (settingValue.Description != null) {
            paragraph.setAttribute("title", settingValue.Description);
        }
        if (settingValue.Type == UserMediaSettingType.Generic) {
            var input = document.createElement("input");
            paragraph.appendChild(input);
            input.type = "checkbox";
            input.id = "setting" + input.type + settingKey;
            input.checked = settingValue.Value;
            input.oninput = function (event) {
                var settings = _this.userMedia.GetSettings();
                var sourceElement = event.srcElement;
                settings[settingKey].Value = sourceElement.checked;
                _this.applyNewSettings(settings);
            };
            var label = document.createElement("label");
            label.innerHTML = settingValue.Name;
            if (settingValue.Description != null) {
                label.classList.add("helptext");
            }
            label.setAttribute("for", input.id);
            paragraph.append(label);
        }
        else if (settingValue.Type == UserMediaSettingType.Range) {
            var settingValueRange = settingValue;
            var label = document.createElement("span");
            label.innerHTML = settingValue.Name;
            if (settingValue.Description != null) {
                label.classList.add("helptext");
            }
            paragraph.appendChild(label);
            var valueLabel_1 = document.createElement("span");
            valueLabel_1.innerHTML = settingValue.Value;
            var br = document.createElement("br");
            paragraph.appendChild(br);
            var input = document.createElement("input");
            paragraph.appendChild(input);
            input.type = "range";
            input.step = settingValueRange.Step.toString();
            input.min = settingValueRange.Min.toString();
            input.max = settingValueRange.Max.toString();
            input.value = settingValue.Value;
            input.oninput = function (event) {
                var sourceElement = event.srcElement;
                valueLabel_1.innerHTML = sourceElement.value;
            };
            input.onchange = function (event) {
                var settings = _this.userMedia.GetSettings();
                var sourceElement = event.srcElement;
                settings[settingKey].Value = sourceElement.value;
                _this.applyNewSettings(settings);
            };
            paragraph.appendChild(valueLabel_1);
        }
        else if (settingValue.Type == UserMediaSettingType.Select) {
            var settingValueOptions = settingValue;
            var label = document.createElement("label");
            label.innerHTML = settingValue.Name;
            if (settingValue.Description != null) {
                label.classList.add("helptext");
            }
            paragraph.append(label);
            var select = document.createElement("select");
            paragraph.appendChild(select);
            for (var i = 0; i < settingValueOptions.Options.length; i++) {
                var option = document.createElement("option");
                option.value = settingValueOptions.Options[i];
                option.innerHTML = option.value;
                select.appendChild(option);
            }
            select.selectedIndex = settingValueOptions.Options.indexOf(settingValue.Value);
            select.id = "setting" + select.type + settingKey;
            select.oninput = function (event) {
                var settings = _this.userMedia.GetSettings();
                var sourceElement = event.srcElement;
                settings[settingKey].Value = settings[settingKey].Options[sourceElement.selectedIndex];
                _this.applyNewSettings(settings);
            };
            label.setAttribute("for", select.id);
        }
    };
    MainUI.prototype.sampleVolume = function (sampleBuffer) {
        var peak = 0;
        sampleBuffer.forEach(function (value) {
            peak = Math.max(peak, Math.abs(value));
        });
        return peak;
    };
    MainUI.prototype.drawAudioVisualisations = function () {
        var _this = this;
        if (!this.shouldDrawVolumeHistogram) {
            return;
        }
        window.requestAnimationFrame(function () { return _this.drawAudioVisualisations(); });
        var canvas = document.getElementById("volumeHistogramCanvas");
        var context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        this.drawAudioHistogram(canvas, context);
        this.drawAudioOscilloscope(canvas, context);
    };
    MainUI.prototype.drawAudioOscilloscope = function (canvas, context) {
        var inputSampleBuffer = this.userMedia.SampleInputFrequency();
        context.fillStyle = "rgba(0, 0, 255, 0.5)";
        var barWidth = (canvas.width / inputSampleBuffer.length);
        var posX = 0;
        for (var i = 0; i < inputSampleBuffer.length; i++) {
            var frequencyAmplitude = inputSampleBuffer[i] / 255;
            var barHeight = frequencyAmplitude * canvas.height;
            context.fillRect(posX, canvas.height - barHeight, barWidth, barHeight);
            posX += barWidth;
        }
    };
    MainUI.prototype.drawAudioHistogram = function (canvas, context) {
        var inputSampleBuffer = this.userMedia.SampleInputTimeDomain();
        var outputSampleBuffer = this.userMedia.SampleOutputTimeDomain();
        this.inputVolumeHistogram.unshift(this.sampleVolume(inputSampleBuffer));
        if (this.inputVolumeHistogram.length > canvas.width) {
            this.inputVolumeHistogram.pop();
        }
        this.ouputVolumeHistogram.unshift(this.sampleVolume(outputSampleBuffer));
        if (this.ouputVolumeHistogram.length > canvas.width) {
            this.ouputVolumeHistogram.pop();
        }
        var audioControls = document.getElementById("audioControls");
        if (audioControls.classList.contains("hidden")) {
            return;
        }
        context.clearRect(0, 0, canvas.width, canvas.height);
        for (var i = 0; i < this.ouputVolumeHistogram.length; i++) {
            var sample = this.ouputVolumeHistogram[i];
            context.fillStyle = "rgba(255, 255, 255, 0.5)";
            context.fillRect(canvas.width - i, canvas.height, 1, -canvas.height * sample);
        }
        for (var i = 0; i < this.inputVolumeHistogram.length; i++) {
            var sample = this.inputVolumeHistogram[i];
            context.fillStyle = sample >= .99 ? "red" : "green";
            context.fillRect(canvas.width - i, canvas.height, 1, -canvas.height * sample);
        }
        var rowTicks = 10;
        var rowHeight = Math.round(canvas.height / rowTicks);
        context.fillStyle = "rgba(255, 255, 255, 0.1)";
        for (var i = 1; i < rowTicks; i++) {
            context.fillRect(0, rowHeight * i, canvas.width, 1);
        }
        var columnTicks = 40;
        var columnWidth = Math.round(canvas.width / columnTicks);
        context.fillStyle = "rgba(255, 255, 255, 0.1)";
        for (var i = 1; i < columnTicks; i++) {
            context.fillRect(columnWidth * i, 0, 1, canvas.height);
        }
    };
    MainUI.prototype.parseStringToType = function (input, type) {
        if (type === "boolean") {
            if (input.toLowerCase() === "true") {
                return true;
            }
            if (input.toLowerCase() === "false") {
                return false;
            }
        }
        if (type === "number") {
            var value = parseFloat(input);
            if (!isNaN(value)) {
                return value;
            }
        }
        throw "Error parsing " + input + " to " + type;
    };
    return MainUI;
}());
export { MainUI };
