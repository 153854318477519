import { PeerConnector } from "./PeerConnector";
var PeerConnectorFactory = /** @class */ (function () {
    function PeerConnectorFactory() {
    }
    PeerConnectorFactory.prototype.CreatePeerConnector = function (shouldOffer) {
        return new PeerConnector(shouldOffer);
    };
    return PeerConnectorFactory;
}());
export { PeerConnectorFactory };
