import { MainUI } from "./MainUI";
import { ChatApp } from "./ChatApp";
import { UserMedia } from "./UserMedia";
import { SessionConfig } from "./SessionConfig";
var Entry = /** @class */ (function () {
    function Entry() {
    }
    Entry.Start = function () {
        if (this.mainUI != null) {
            return;
        }
        var isNotRewriteUrl = window.location.pathname.endsWith('/index.html');
        var roomId;
        if (isNotRewriteUrl) {
            roomId = window.location.hash.replace('#', '');
        }
        else {
            roomId = window.location.pathname.replace('/', '');
        }
        var sessionConfig = new SessionConfig(roomId);
        if (roomId.length == 0) {
            if (isNotRewriteUrl) {
                window.history.replaceState(null, document.title, '#' + sessionConfig.RoomId);
            }
            else {
                window.history.replaceState(null, document.title, '/' + sessionConfig.RoomId);
            }
        }
        var userMedia = new UserMedia();
        var app = new ChatApp(userMedia, sessionConfig);
        this.mainUI = new MainUI(app, userMedia);
        this.mainUI.initialise();
    };
    return Entry;
}());
export { Entry };
